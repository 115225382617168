import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import "./EventLobby.sass"
import logo from "src/static/logo-adigital-negativo.png"
import TimerElement from "../../components/TimerElement/TimerElement"
import QRCode from "react-qr-code"
import { getEvent, setAssist } from "../../hooks/api2"

const EventLobby = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const [eventMode, setEventMode] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [event, setEvent] = useState(undefined)
  const [auth, setAuth] = useState(undefined)
  const [countDown, setCountDown] = useState(undefined)
  const [queueing, setQueueing] = useState(false)

  let query = useQuery()
  const { id, mode } = useParams(); // Required by url

  useEffect(async () => {
    const auth = query.get("auth") || localStorage.getItem('ssoToken')
    setAuth(auth)

    if (!auth) {
      window.location.replace(
        window.location.protocol + "//" + window.location.host + "/login2?redirect=" + window.location.toString()
      )
    }

    const eventReq = await getEvent(id, mode, auth)

    if (eventReq.status === 403) {
      setError("¡Vaya! Parece que no estás inscrito/a en este evento. Si se trata de un error, escribe a info@adigital.org.")
    } else if (eventReq.status === 404) {
      setError("No se ha encontrado el evento solicitado.")
    } else if (!eventReq.ok) {
      setError("¡Vaya! Ha ocurrido un error. Por favor, inténtalo de nuevo o contacta con nosotros en info@adigital.org.")
    } else {
      const event = await eventReq.json()
      event.start_date_timestamp = new Date(event.start_date).getTime()

      setEventMode(mode)
      setEvent(event)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (event) {
        const timeLeft = event.start_date_timestamp - new Date().getTime()
        if (timeLeft >= 0) {
          setCountDown(timeLeft)
        } else if (document.hasFocus() && eventMode !== 'presential' && !queueing) {
          setQueueing(true)
          try {
            await setAssist(event.id, eventMode, auth)
          } catch (e) {
            console.log(e)
          }
          //if (!assistRes.ok || !event.link) {
          if (!event.link) { // Always redirect to event, even if setAssist failed
            setError("¡Vaya! Ha ocurrido un error. Por favor, inténtalo de nuevo o contacta con nosotros en info@adigital.org.")
          } else {
            window.location.href = event.link
          }

          clearInterval(interval)
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [event])

  let content
  if (error) {
    content = <h1>{error}</h1>
  } else if (eventMode === 'presential' && event) {
    content = (
      <div className="qr-section">
        <QRCode value={`${event.id}:${auth}`} />
      </div>
    )
  } else if (!countDown) {
    content = <h2>Cargando datos del evento...</h2>
  } else if (queueing) {
    content = <h2>Accediendo al evento...</h2>
  } else {
    const getDrawValues = (countDown) => {
      const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
      const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

      return [days, hours, minutes, seconds]
    }
    const drawValues = getDrawValues(countDown)
    content = (
      <div>
        <h2>Se accederá al evento en:</h2>
        <div id="timers">
          <TimerElement time={drawValues[0]} text={"Días"} />
          <TimerElement time={drawValues[1]} text={"Horas"} />
          <TimerElement time={drawValues[2]} text={"Minutos"} />
          <TimerElement time={drawValues[3]} text={"Segundos"} />
        </div>
      </div>
    )
  }
  return (
    <div id="waiting">
      <div className="header">
        <img src={logo} />
      </div>
      {content}
    </div>
  )
}

export default EventLobby

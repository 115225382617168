import React, { useEffect, useState } from "react"
import "./Login2.sass"
import { useLocation } from "react-router-dom"
import { ssoEmailAuthentication, ssoSiteSignIn, validateSsoSessionToken } from "../../hooks/api2"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"

const Login2 = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const logout = () => {
    localStorage.removeItem("ssoToken")
    window.location.replace(window.location.protocol + "//" + window.location.host + "/error")
  }

  let query = useQuery()
  let [mail, setMail] = useState("")
  let [loginDone, setLoginDone] = useState(false)
  let [sendingRequest, setSendingRequest] = useState(false)
  let [loginFail, setLoginFail] = useState(false)

  // SSO Token validation and installation
  useEffect(async () => {
    const ssoToken = query.get("ssoToken")

    if (ssoToken && ssoToken !== localStorage.getItem('ssoToken')) {
      if (!(await validateSsoSessionToken({ ssoToken })).ok) logout()
      else {
        localStorage.setItem("ssoToken", ssoToken)
        window.location.reload()
      }
    }
  })

  // Domain Token request and redirect
  useEffect(async () => {
    const domain = query.get("domain")
    const redirect = query.get("redirect")
    const ssoToken = localStorage.getItem('ssoToken')

    if (ssoToken && domain) {
      setSendingRequest(true)
      const siteTokenResponse = await ssoSiteSignIn(ssoToken, domain)

      if (!siteTokenResponse.ok) logout()
      else {
        const siteToken = (await siteTokenResponse.json()).token

        const redirectUrl = (new URL(decodeURIComponent(redirect || `https://${domain}`)))
        redirectUrl.searchParams.append('ds_session', siteToken)
        window.location.replace(redirectUrl.toString())
      }
    }
  })

  useEffect(async () => {
    const domain = query.get("domain")

    if (!domain && localStorage.getItem('ssoToken')) {
      window.location.replace(window.location.protocol + "//" + window.location.host + "/userPanel")
    }
  })

  // Not installing SSO token & not auto-signing-in, so lets show the login screen
  if (loginFail) {
    return (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>
        <div className="login-content">
          <div className="form-login">
            <div className="form-content">
              <h2>¡Usuario no encontrado!</h2>
              <p>
                Parece que no estás registrado. Recuerda que debes acceder con tu correo electrónico de empresa. Puedes{" "}
                <a href="" onClick={() => window.location.reload()}>
                  volver a intentarlo aquí
                </a>{" "}.
              </p>
              <p>
                Si necesitas ayuda, puedes contactar con nosotros en:{" "}
                <a href="mailto: info@adigital.org">info@adigital.org</a>.
              </p>
            </div>
          </div>
          <div className="login-image">
            <img src={imageLogin} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>
        {!loginDone ? (
          <div className="login-content">
            <div className="form-login">
              <form
                className="form"
                onSubmit={async (e) => {
                  setSendingRequest(true)
                  e.preventDefault()
                  const res = await ssoEmailAuthentication(
                    mail.trim().toLowerCase(),
                    {
                      domain: query.get("domain"),
                      redirect: query.get("redirect")
                    }
                  )
                  if (res.status === 200) {
                    setSendingRequest(false)
                    setLoginDone(true)
                  } else {
                    if (res.status === 404) {
                      setLoginFail(true)
                    }
                  }
                  e.target.reset()
                }}
              >
                <div className="form-content">
                  <h2>Bienvenido/a</h2>
                  <p>
                    Introduce tu correo electrónico de empresa para acceder como asociado de Adigital. Recibirás un correo electrónico con el enlace de acceso.
                  </p>
                  <input
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="Correo electrónico"
                    type="text"
                  />
                  <button
                    disabled={mail === "" || sendingRequest === true}
                    className={mail === "" || sendingRequest === true ? "empty-button" : "ready-button"}
                    type="submit"
                  >
                    {sendingRequest ? 'Accediendo...' : 'Acceder'}
                  </button>
                  <p className="even-smaller-paragraph">
                    Tus datos serán tratados por Adigital con la finalidad de prestarte los servicios solicitados.
                    Consulta más información y cómo ejercer tus derechos en nuestra{" "}
                    <a href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/">
                      Política de privacidad
                    </a>
                    . Consulta los términos y condiciones en{" "}
                    <a href="https://www.adigital.org/aviso-legal/">este enlace</a>.
                  </p>
                </div>
              </form>
            </div>
            <div className="login-image">
              <img src={imageLogin} />
            </div>
          </div>
        ) : (
          <div className="login-content">
            <div className="form-login">
              <div className="form-content">
                <h2>¡Listo!</h2>
                <p>Acabamos de enviar a tu correo electrónico el enlace de acceso.</p>

                <p className="smaller-paragraph">
                  Si transcurridos unos minutos no lo recibes, {" "}
                  <a href="" onClick={() => window.location.reload()}>
                    haz clic aquí para volver a intentarlo
                  </a>{" "}
                  o contacta con nosotros en <a href="mailto: info@adigital.org">info@adigital.org</a>.
                </p>
              </div>
            </div>
            <div className="login-image">
              <img src={imageLogin} />
            </div>
          </div>
        )}
      </div>
    )
  }

}

export default Login2
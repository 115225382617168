export const ssoEmailAuthentication = (email, body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/ssoEmailAuthentication/${email}`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const validateSsoSessionToken = (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/validateSsoSessionToken`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const ssoSiteSignIn = (ssoToken, domain) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/ssoSiteSignIn`, {
    headers,
    body: JSON.stringify({ssoToken, domain}),
    method: "POST",
  })
}

export const setInteraction = (interactionId, auth) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/content/${interactionId}?auth=${auth}`, {
    headers,
    method: "GET",
  })
}

export const getEvent = (id, mode, auth) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/event/${id}/${mode}?auth=${auth}`, {
    headers,
    method: "GET",
  })
}

export const setAssist = (id, mode, auth) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/event/${id}/assist/${mode}/queue?auth=${auth}`, {
    headers,
    method: "GET",
  })
}